export enum BillingCycles {
    MONTHLY = "monthly",
    YEARLY = "yearly",
    LIFETIME = "one_time",
}

export enum PlanNames {
    PRO = "pro",
    PREMIUM = "premium",
    BASIC = "basic",
    BUSINESS = "business",
    STARTER = "starter",

    AppSumo1 = 'AppSumo Tier 1',
    AppSumo2 = 'AppSumo Tier 2',
    AppSumo3 = 'AppSumo Tier 3',
}