import { AxiosError, AxiosResponse } from "axios";
import { CommonConstants } from "../constants/common";
import store from "../store/configureStore";
import { globalActions } from "../store/actions";
import { toast } from "react-toastify";
import Cookie from "js-cookie";
import { exemptedMessages } from "./exemptedMessages";

export const headerify = async (request: any) => {
  store.store.dispatch(globalActions.showTopLoading());

  const token: any = Cookie.get(CommonConstants.USER_TOKEN)
    ? Cookie.get(CommonConstants.USER_TOKEN)
    : null;

  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: token ? `Bearer ${token}` : null
    }
  };
};

const forbiddenExempted = (): boolean => {
  const pathname = window.location.pathname;

  return (
    pathname.split("/").includes("posts") || 
    pathname.split("/").includes("library") || 
    pathname.split("/").includes("lectures") || 
    pathname.split("/").includes("courses") || 
    pathname.split("/").includes("events") ||
    pathname.split("/")[1] === "pages-preview"
  );
}

export const handleError = (error: AxiosError) => {
  store.store.dispatch(globalActions.hideTopLoading());

  if (!error || !error.response) {
    return;
  }

  if (error.response.data && error.response.data.errors) {
    if(
      window.location.pathname.split("/")[1] === "new-password" ||
      window.location.pathname.split("/")[1] === "new-name"
    ) {
      return  Promise.reject(error.response);
    }

    const groupAppErrors = error.response.data.errors;
    const firstErrorKey = Object.keys(error.response.data.errors)[0];
    
    const emailId422ErrorCheck = (error.response.config.url && error.response.config.url.includes('email-identities')) && error.response.status === 422

    if(firstErrorKey) {
      const displayError = groupAppErrors[firstErrorKey][0];
      if(error.response.status !== 400 && error.response.status !== 403){
        if (!emailId422ErrorCheck) {
          toast.error(displayError, {
            // type: 'error',
            autoClose: 3000,
            position: "top-center",
            hideProgressBar: true,
            theme: "light",
          });
        }
      }
    }
  }

  switch (error.response.status) {
    case 400:
      if(
        error.config.url &&
        error.config.url.includes('pages') &&
        error.config.url.includes('publish')
      ) {
        return Promise.reject(error.response);
      }
      toast.error(`${error.response.data.message}`, {
        // type: 'error',
        autoClose: 3000,
        position: "top-center",
        hideProgressBar: true,
        theme: "light",
      });
      return Promise.reject(error.response);
    case 401:
      return Promise.reject(error.response);
    case 404:
      return Promise.reject(error.response);
    case 422:
      return Promise.reject(error.response);
    case 403: {
      if (forbiddenExempted()) {
        return Promise.reject(error.response);
      }
      if (error.response.config.url && !error.response.config.url.includes("pinned") && !error.response.config.url.includes('s3/sign') && !error.response.config.url.includes('courses')) {
        if (error.response.config.url && error.response.config.url.includes("posts") && error.response.config.url.includes("postable")) {
          // toast(`Log in to view page.`, {
          //   type: 'error',
          //   autoClose: 3000,
          // });
        } else {

          if(
            window.location.pathname.split("/").includes("library") && 
            window.location.pathname.split("/").includes("lectures") &&
            exemptedMessages.includes(error.response.data.message)
          ) {
            
          } else {
            if (
              (window.location.pathname.split("/").includes("channel") &&
                exemptedMessages.includes(error.response.data.message))
              ||
              (error.response.config.url && error.response.config.url.includes("featured"))
            ) {

            }
            else {
              if (
                error.response.data.message.includes('User is blocked in community.')
              ) {
              }
              else {
                toast.error(`${error.response.data.message}`, {
                  // type: 'error',
                  autoClose: 3000,
                  position: "top-center",
                  hideProgressBar: true,
                  theme: "light",
                });
              }
            }
          }
          
        }
      }

      return Promise.reject(error.response);
    }
    default: {
      // toast("Something went wrong, please try again.", {
      //   type: 'error',
      //   autoClose: 3000,
      // });
      return Promise.reject(error.response);
    }
  }
};

export const handleResponse = (response: AxiosResponse) => {
  store.store.dispatch(globalActions.hideTopLoading());
  return response.data;
};

export const createQueryString = (object: any) => {
  if (!object || Array.isArray(object) || typeof object === "string") {
    return Promise.reject({ error: "Something went wrong" });
  }
  let str = "";
  Object.keys(object).forEach(
    (key, i) =>
      (str = `${str}${key}=${object[key]}${
        i < Object.keys(object).length - 1 ? "&" : ""
      }`)
  );
  return str;
};

export const createDynamicRouteRequest = (
  httpClient: any,
  getRouteFn: any,
  staticConfig = {}
) => (getRouteArgument: any, { body = {}, dynamicConfig = {} } = {}) => {
  const route = getRouteFn(getRouteArgument);
  const config = {
    ...staticConfig,
    ...dynamicConfig
  };
  return body ? httpClient(route, body, config) : httpClient(route, config);
};

export const createSimpleRequest = (
  httpClient: any,
  route: string,
  staticConfig = {}
) => ({ body = {}, dynamicConfig = {} } = {}) => {
  const config = {
    ...staticConfig,
    ...dynamicConfig
  };
  return body ? httpClient(route, body, config) : httpClient(route, config);
};

export const createQueryRequest = (
  httpClient: any,
  route: string,
  staticConfig = {}
) => (params: any, dynamicConfig = {}) => {
  const config = {
    ...staticConfig,
    ...dynamicConfig
  };
  const query = createQueryString(params);
  const url = `${route}${query}`;
  return httpClient(url, config);
};
