import Cookie from "js-cookie";
import appConfig from "../appConfig";

export function getCookieValue(key: string) {
  const value = Cookie.get(key);
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
}


//TOD0
export function setCookieValue(key: string, value: any, expires: any = 14) {
  if(window.location.hostname.includes('group.app')
    || window.location.hostname.includes('group.localhost')
  ){
    Cookie.set(key, value, { path: "/", domain: appConfig.domain.baseDomain, expires, });
  }else {
    Cookie.set(key, value, { path: "/", domain: window.location.hostname, expires, });
  }
}

export function removeCookieValue(key: string) {
  if(window.location.host.includes('group.app')
    || window.location.host.includes('group.localhost')
  ){
    Cookie.remove(key, { path: "/", domain: appConfig.domain.baseDomain });
  }else {
    Cookie.remove(key, { path: "/", domain: window.location.hostname });
  }
}
